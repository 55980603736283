body {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

:root {
  --clr-primary-100: #f0e2db;
  --clr-primary-200: #e6ddcb;
  --clr-primary-300: #dbdbbd;
  --clr-primary-400: #b9c69f;
  --clr-primary-500: #796;
  --clr-primary-600: #415841;
  --clr-primary-700: #2e3831;
  --clr-primary-800: #242927;
  --clr-primary-900: #191a1a;
  --clr-accent-100: #c5c1f0;
  --clr-accent-200: #b9b0e8;
  --clr-accent-300: #af9fdf;
  --clr-accent-400: #977ece;
  --clr-accent-500: #6e4db3;
  --clr-accent-600: #4f3a78;
  --clr-accent-700: #3d2d52;
  --clr-accent-800: #332640;
  --clr-accent-900: #281f2e;
  --clr-neutral-100: #fff;
  --clr-neutral-150: #f0f0f0;
  --clr-neutral-200: #e0e0e0;
  --clr-neutral-250: #cfcfcf;
  --clr-neutral-300: #bfbfbf;
  --clr-neutral-350: #b0b0b0;
  --clr-neutral-400: #a1a1a1;
  --clr-neutral-450: #8f8f8f;
  --clr-neutral-500: gray;
  --clr-neutral-550: #707070;
  --clr-neutral-600: #616161;
  --clr-neutral-650: #4f4f4f;
  --clr-neutral-700: #404040;
  --clr-neutral-750: #303030;
  --clr-neutral-800: #212121;
  --clr-neutral-850: #0f0f0f;
  --clr-neutral-900: #000;
  --clr-error-400: #ed5e6a;
  --clr-error-500: #dd3c49;
  --clr-error-600: #b82e39;
  --clr-warn-400: #f7f7a1;
  --clr-warn-500: #ecec79;
  --clr-warn-600: #dbdb57;
  --clr-success-400: #cffcd2;
  --clr-success-500: #a3f5aa;
  --clr-success-600: #7de886;
}

:root {
  --ff-primary: "Courier New", Courier, monospace;
}

.card-action {
  background-color: var(--clr-accent-500);
  color: var(--clr-primary-200);
  float: right;
  border: none;
  border-radius: 15px;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 3.5rem;
  font-size: 1.25em;
  text-decoration: none;
  display: flex;
}

.card-action:hover {
  background-color: var(--clr-neutral-800);
}

.card-action img {
  height: 1.5em;
  margin-right: 1rem;
}

.card-container {
  width: 90%;
  margin: auto;
}

.card-left, .card-right {
  background-color: var(--clr-primary-300);
  color: var(--clr-neutral-900);
  flex-direction: column;
  height: min-content;
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  overflow: hidden;
}

.card-left {
  border-right: 10px solid var(--clr-accent-500);
  border-radius: 50px 0 0 50px;
}

.card-right {
  border-left: 10px solid var(--clr-accent-500);
  border-radius: 0 50px 50px 0;
}

.card-image {
  grid-area: image;
}

.card-image img {
  object-fit: cover;
  object-position: center;
  width: min(100%, 20rem);
  margin: auto;
  display: block;
}

.card-details {
  grid-area: details;
  padding: 1rem;
}

.card-title:after {
  border-bottom: 1px solid var(--clr-neutral-900);
  content: "";
  width: 100%;
  display: block;
}

@media (min-width: 860px) {
  .card-container {
    flex-direction: column;
    justify-content: center;
    height: 75vh;
    display: flex;
  }

  .card-left {
    grid-template-columns: 25% 75%;
    grid-template-areas: "image details";
    display: grid;
  }

  .card-right {
    grid-template-columns: 75% 25%;
    grid-template-areas: "details image";
    display: grid;
  }

  .card-column {
    grid-template-columns: 50% 50%;
    display: grid;
  }

  .card-container {
    width: max(50%, 50rem);
  }
}

body {
  background-color: var(--clr-neutral-200);
  color: var(--clr-neutral-800);
  font-family: var(--ff-primary);
}

.app {
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.nav {
  background-color: var(--clr-neutral-200);
  flex-direction: row;
  width: 100%;
  height: 100lvh;
  padding: 3rem;
  transition: all .5s cubic-bezier(.075, .82, .165, 1);
  display: flex;
  position: absolute;
}

.nav.closed {
  margin-left: -100%;
}

.nav-icon {
  z-index: 1;
  margin: 1rem;
  position: absolute;
}

.nav-icon svg {
  color: var(--clr-neutral-800);
}

.nav-icon.closed svg {
  color: var(--clr-neutral-200);
}

.nav-icon:not(.closed) svg:first-child, .nav-icon.closed svg:nth-child(2) {
  display: none;
}

.nav-item-container {
  margin: auto;
}

.nav-item {
  margin-top: 2rem;
}

.nav-item div {
  grid-template-columns: 50% 50%;
  margin-top: 1rem;
  display: grid;
}

.nav-item-title:after {
  border-bottom: 1px solid var(--clr-neutral-900);
  content: "";
  width: 100%;
  display: block;
}

.three-container {
  width: 100%;
}

@media (min-width: 860px) {
  .nav {
    width: 38%;
    position: relative;
  }

  .nav.closed {
    margin-left: 0;
  }

  .nav-icon {
    display: none;
  }

  .three-container {
    width: 62%;
  }
}

/*# sourceMappingURL=404.2c83701d.css.map */
